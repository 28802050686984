import { FC, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import Link from '@components/Link';
import styles from './css';

type Props = {
  btnStyle?:
    | ''
    | 'black'
    | 'clear'
    | 'danger'
    | 'gradation'
    | 'green'
    | 'outline'
    | 'purple'
    | 'signup'
    | 'white';
  customClass?: string;
  disabled?: boolean;
  icon?: null | ReactNode;
  iconPosition?: 'end' | 'start';
  id?: string;
  isSecondary?: boolean;
  label: ReactNode | string;
  link?: string;
  name?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  position?: 'center' | 'left' | 'responsive' | 'right';
  size?:
    | 'large'
    | 'medium'
    | 'small'
    | 'x-large'
    | 'x-medium'
    | 'x-small'
    | 'xx-large';
  testId?: string;
  type?: 'button' | 'submit';
  width?: 'auto' | 'full' | 'responsive';
};

const Button: FC<Props> = ({
  btnStyle: buttonStyle = '',
  customClass = '',
  disabled = false,
  icon = null,
  iconPosition = 'start',
  id = '',
  isSecondary = false,
  label,
  link,
  name,
  onClick = () => {},
  position = 'center',
  size = 'small',
  testId,
  type = 'button',
  width = 'auto',
}) => {
  const buttonClassName = classNames(
    'btn',
    `size-${size}`,
    `width-${width}`,
    `position-${position}`,
    {
      [`btn-${buttonStyle}`]: buttonStyle,
      'btn-primary': !disabled && !isSecondary,
      'btn-secondary': !disabled && isSecondary,
    },
    customClass
  );

  const Wrapper = link ? Link : 'button';
  const props = link
    ? { href: link }
    : {
        'data-testid': testId,
        onClick,
        type,
      };

  return (
    <>
      <Wrapper
        className={buttonClassName}
        disabled={disabled}
        id={id}
        name={name}
        {...props}
      >
        <div className={`button-container icon-${classNames(iconPosition)}`}>
          {icon && <div className="button-icon">{icon}</div>}
          <div>{label}</div>
        </div>
      </Wrapper>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default Button;
