import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .btn {
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    font-weight: bold;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    /* primary */
    &.btn-primary {
      color: ${COLORS.white};
      background-color: ${COLORS.primary};
      border: 1px solid ${COLORS.primary};
    }
    /* secondary */
    &.btn-secondary {
      color: ${COLORS.primary};
      background-color: ${COLORS.white};
      border: 1px solid ${COLORS.primary};
    }
    /* clear */
    &.btn-clear {
      color: ${COLORS.primary};
      background-color: ${COLORS.white};
      border: none;
    }
    /* danger */
    &.btn-danger {
      color: ${COLORS.red600};
      background-color: ${COLORS.white};
      border: 1px solid ${COLORS.red600};
    }
    /* gradation */
    &.btn-gradation {
      background: linear-gradient(93.83deg, #19c2f8 0%, #3f9fff 100%);
      box-shadow:
        0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    }
    /* black */
    &.btn-black {
      color: ${COLORS.white};
      background-color: ${COLORS.black900};
      border: 1px solid ${COLORS.black900};
    }
    /* black outline */
    &.btn-outline {
      color: ${COLORS.black900};
      background-color: ${COLORS.white};
      border: 1px solid ${COLORS.black900};
    }
    /* green */
    &.btn-green {
      color: ${COLORS.white};
      background: #6cc920;
      border: 2px solid #3b9010;
    }
    /* white */
    &.btn-white {
      color: ${COLORS.purple600};
      background: ${COLORS.white};
      border: 2px solid ${COLORS.white};
    }
    /* purple */
    &.btn-purple {
      color: ${COLORS.white};
      background: ${COLORS.purple600};
      border: none;
    }
    /* signup */
    &.btn-signup {
      border: 1px solid ${COLORS.signupBorderColor};
      background: linear-gradient(90deg, #c44ca2 0%, #7149ce 100%);
    }

    &.btn-big-border-radius {
      border-radius: 40px;
    }

    &:not(:disabled) {
      cursor: pointer;
      &:focus,
      &:hover {
        &.btn-primary {
          background-color: ${COLORS.purple700};
        }
        &.btn-secondary {
          background-color: ${COLORS.hoverSecondary};
        }
        &.btn-clear {
          background-color: initial;
        }
        &.btn-danger {
          background-color: ${COLORS.red100};
        }
        &.btn-black {
          background-color: ${COLORS.black700};
        }
        &.btn-green {
          background-color: #3b9010;
        }
        &.btn-white {
          background-color: ${COLORS.white};
          opacity: 0.8;
        }
      }
    }
    &:disabled {
      color: ${COLORS.black400};
      border: 1px solid ${COLORS.black200};
      /* Overwrite both background and background-color properties */
      background: ${COLORS.black200};
    }

    /* size */
    &.size-x-small {
      font-size: 12px;
      padding: 8px 16px;
    }
    &.size-small {
      font-size: 14px;
      padding: 8px 14px;
    }
    &.size-medium {
      font-size: 16px;
      padding: 12px 24px;
    }
    &.size-x-medium {
      font-size: 20px;
      padding: 16px 32px;
      border-radius: 32px;
    }
    &.size-large {
      font-size: 16px;
      padding: 12px 44px;
    }
    &.size-x-large {
      font-size: 16px;
      padding: 20px 32px;
      border-radius: 32px;
    }
    &.size-xx-large {
      font-size: 16px;
      padding: 28px 116px;
      border-radius: 80px;
    }

    /* width */
    &.width-auto {
      display: inline-flex;
      width: auto;
    }
    &.width-full {
      display: flex;
      width: 100%;
    }
    &.width-responsive {
      display: flex;
      width: 100%;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        display: flex;
        width: auto;
      }
    }

    /* position */
    &.position-center {
      display: flex;
      margin: auto;
    }
    &.position-left {
      display: flex;
      margin-right: auto;
    }
    &.position-right {
      display: flex;
      margin-left: auto;
    }
    &.position-responsive {
      display: flex;
      margin: auto;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        margin: 0 0 0 auto;
      }
    }

    .button-container .button-icon {
      display: inline-flex;
      margin-right: 8px;
      align-items: center;
    }

    .button-container.icon-end .button-icon {
      margin-right: 0;
    }

    .button-container {
      display: flex;
      flex-direction: row;
    }
    .button-container.icon-end {
      flex-direction: row-reverse;
    }
  }
`;

export default styles;
