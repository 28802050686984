import { FC, ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

type LinkProps = {
  children?: ReactNode;
  className?: string;
  style?: Record<string, unknown>;
  target?: HTMLAnchorElement['target'];
} & NextLinkProps;

const Link: FC<LinkProps> = ({
  as,
  children,
  className,
  href = '',
  locale,
  onClick,
  prefetch,
  style,
  target = '_self',
}) => (
  <NextLink
    as={as}
    className={className}
    href={as || href}
    locale={locale}
    prefetch={prefetch}
    style={style}
    target={target}
    onClick={onClick}
  >
    {children}
  </NextLink>
);

export default Link;
